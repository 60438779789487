import React from "react";
import Select from "react-select";

const categoryIsExclusive = {
  Content: false,
  Frequency: true,
  "Game Length": true,
  Inclusivity: false,
  Playstyle: true,
  Tools: false,
};

function byLabel(a, b) {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
}

function selectedTags(tagIds, tagOptions) {
  return tagIds.map((tid) =>
    tagOptions.flatMap((c) => c.options).find((o) => o.value === tid)
  );
}

function FormInputs({ tagIds }) {
  return tagIds.map((tid) => (
    <input
      key={tid}
      type="hidden"
      name="campaign[update_tag_ids][]"
      value={tid}
    />
  ));
}

export default function TagSelect({
  tagOptions,
  tagIds,
  onChange,
  includeInputs,
}) {
  const st = selectedTags(tagIds, tagOptions).sort(byLabel);
  const selectedCategories = st.map((t) => t.category);
  const availableOptions = tagOptions
    .filter(
      (c) =>
        !(selectedCategories.includes(c.label) && categoryIsExclusive[c.label])
    )
    .sort(byLabel);

  return (
    <>
      <Select
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 5 }),
        }}
        classNamePrefix="react-select-tags"
        className="control"
        options={availableOptions}
        isMulti
        onChange={onChange}
        value={st}
        inputId="tags"
        placeholder="Tags"
      />
      {includeInputs ? <FormInputs tagIds={tagIds} /> : null}
    </>
  );
}
