import React, { useEffect, useContext } from "react";
import { store } from "./Store";

export default function SavedSearches() {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { savedSearches, selectedSearchId } = globalState.state;

  useEffect(() => {
    fetch("/saved_searches")
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: "SET_SAVED_SEARCHES", savedSearches: response });
      });
  }, []);

  return (
    <div className={`field ${selectedSearchId ? "has-addons" : ""}`}>
      <div className="control">
        <div className="select">
          <select
            className="select"
            id="saved_searches"
            onChange={(e) => {
              dispatch({
                type: "SET_SELECTED_SEARCH",
                selectedSearchId: e.target.value,
              });
            }}
            value={selectedSearchId}
          >
            <option value="" disabled hidden>
              Saved Searches
            </option>
            {savedSearches.map((ss) => {
              return (
                <option key={ss.id} value={ss.id}>
                  {ss.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {selectedSearchId ? (
        <div className="control">
          <a
            className="button is-danger is-inverted saved-search-delete"
            rel="nofollow"
            data-method="delete"
            href={`/saved_searches/${selectedSearchId}`}
          >
            Delete
          </a>
        </div>
      ) : null}
    </div>
  );
}
