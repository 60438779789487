import React, { useContext } from "react";
import { store } from "./Store";

function campaignRowCard(campaign) {
  return (
    <div
      key={campaign.id}
      className="card mb-2 campaign-card campaign-card-animate"
    >
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              <img src={campaign.game_img_url} alt={campaign.game_name} />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">
              <a href={`/campaigns/${campaign.id}`} target="_blank">
                {campaign.name}
              </a>
            </p>
            <p className="subtitle is-6 mb-2">
              {campaign.game_name} • {campaign.user_count}{" "}
              <i className="fa fa-users"></i>
            </p>
            {campaign.remote ? null : (
              <p className="subtitle is-6 mb-2">
                {campaign.city}, {campaign.state}
              </p>
            )}
            <div className="tags">
              {campaign.tag_names.map((tag) => (
                <span key={tag} className="tag is-darkest">
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <div className="media-right is-hidden-mobile">
            <a
              href={`/campaigns/${campaign.id}`}
              className="button is-primary"
              target="_blank"
            >
              View
              <i className="fa fa-external-link ml-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function noCampaigns(loggedIn) {
  const globalState = useContext(store);
  const { selectedSearchId } = globalState.state;

  if (loggedIn) {
    return (
      <div className="mt-5 has-text-centered">
        <h1 className="title">No Campaigns Found</h1>
        {!selectedSearchId && (
          <h2 className="subtitle">
            Save your search using the button above. We'll email you if anything
            matches your search later.
          </h2>
        )}
        <a href="/campaigns/new" className="button is-primary">
          Create Campaign
        </a>
      </div>
    );
  } else {
    return (
      <div className="mt-5 has-text-centered">
        <h1 className="title">No Campaigns Found</h1>
        {!selectedSearchId && (
          <h2 className="subtitle">
            Sign up and save your search, we'll send you an email when we find
            new matches
          </h2>
        )}
        <a href="/users/sign_up" className="button is-primary">
          Sign Up
        </a>
      </div>
    );
  }
}

export default function CampaignsTable({ campaigns, loggedIn }) {
  if (campaigns.length === 0) return noCampaigns(loggedIn);

  return (
    <>
      <div className="content">
        <span className="tag is-light is-medium">
          <b>{campaigns.length} &nbsp;</b>{" "}
          {campaigns.length > 1 ? "Campaigns" : "Campaign"} Found
        </span>
      </div>
      {campaigns.map(campaignRowCard)}
    </>
  );
}
