import React, { useContext } from "react";
import { store } from "./Store";

export default function CityStateInputs({ stateOptions }) {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { city, state } = globalState.state;
  return (
    <div className="flex-centered">
      <div className="field mb-0 mr-3">
        <div className="control is-expanded">
          <input
            className="input"
            type="text"
            name="city"
            id="city"
            placeholder="City"
            value={city}
            onChange={(e) =>
              dispatch({ type: "SET_CITY", city: e.target.value })
            }
          />
        </div>
      </div>
      <div className="field">
        <div className="control is-expanded">
          <div className="select" style={{ width: "100%" }}>
            <select
              name="state"
              id="state"
              style={{ width: "100%" }}
              onChange={(e) =>
                dispatch({ type: "SET_STATE", state: e.target.value })
              }
              value={state}
            >
              {stateOptions.map((s) => (
                <option key={s} value={s}>
                  {s}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
