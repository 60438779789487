import React, { createContext, useReducer } from "react";

const initialState = {
  remote: true,
  state: "Alabama",
  city: "",
  dirty: true,
  gameIds: [],
  tagIds: [],
  campaigns: null,
  selectedSearchId: "",
  savedSearches: [],
  showSave: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_SELECTED_SEARCH":
        const selectedSearch = state.savedSearches.find(
          (ss) => ss.id === action.selectedSearchId
        );
        if (selectedSearch) {
          return {
            ...state,
            selectedSearchId: action.selectedSearchId,
            gameIds: selectedSearch.game_ids,
            tagIds: selectedSearch.tag_ids,
            remote: selectedSearch.remote,
            city: selectedSearch.city,
            state: selectedSearch.state,
            dirty: true,
          };
        } else {
          return {
            ...state,
            selectedSearchId: "",
          };
        }
      case "ADD_SAVED_SEARCH":
        let { savedSearches } = state;
        return {
          ...state,
          savedSearches: [...savedSearches, action.savedSearch],
          selectedSearchId: action.savedSearch.id,
          showSave: false,
          dirty: false,
        };
      case "SET_SAVED_SEARCHES":
        return { ...state, savedSearches: action.savedSearches };
      case "SET_CAMPAIGNS":
        return { ...state, campaigns: action.campaigns };
      case "SEARCH":
        return {
          ...state,
          dirty: action.dirty,
          showSave: action.showSave,
          campaigns: null,
        };
      case "SET_REMOTE":
        return {
          ...state,
          remote: action.remote,
          dirty: true,
          selectedSearchId: "",
        };
      case "SET_GAME_IDS":
        return {
          ...state,
          gameIds: action.gameIds,
          dirty: true,
          selectedSearchId: "",
        };
      case "SET_TAG_IDS":
        return {
          ...state,
          tagIds: action.tagIds,
          dirty: true,
          selectedSearchId: "",
        };
      case "SET_CITY":
        return {
          ...state,
          city: action.city,
          dirty: true,
          selectedSearchId: "",
        };
      case "SET_STATE":
        return {
          ...state,
          state: action.state,
          dirty: true,
          selectedSearchId: "",
        };
      default:
        throw new Error(`Unknown action: ${action.type}`);
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
