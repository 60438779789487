import React, { useState, useContext } from "react";
import { store } from "./Store";

function NameInput({ expand, nameError, onChangeName }) {
  if (!expand) return null;

  return (
    <input
      id="saved_search_name"
      className={`input ${nameError ? "is-danger" : ""}`}
      type="text"
      placeholder={nameError ? "Can't Be Blank" : "Search Name"}
      onChange={onChangeName}
    />
  );
}

export default function SaveSearchButton({ csrf }) {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [name, setName] = useState("");
  const [expand, setExpand] = useState(false);
  const [nameError, setNameError] = useState(false);

  const {
    gameIds,
    tagIds,
    remote,
    city,
    state,
    savedSearches,
  } = globalState.state;

  const onChangeName = (e) => {
    if (e.target.value.trim().length > 0) setNameError(false);
    setName(e.target.value);
  };

  const saveSearch = () => {
    if (name.trim().length === 0) {
      setNameError(true);
      return;
    }

    const params = {
      saved_search: {
        name,
        remote,
        city,
        state,
        game_ids: gameIds,
        tag_ids: tagIds,
      },
    };

    fetch(`/saved_searches`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "X-CSRF-TOKEN": csrf,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((savedSearch) => {
        dispatch({
          type: "ADD_SAVED_SEARCH",
          savedSearch,
        });
      });
  };

  const limitReached = savedSearches.length >= 5;
  const noGameIds = gameIds.length === 0;
  const isDisabled = limitReached || noGameIds;

  let tooltip;
  if (limitReached) {
    tooltip =
      "You are limited to 5 saved searches, delete existing saved searches to make room for new ones.";
  } else if (noGameIds) {
    tooltip = "You must select at least 1 game to save a search.";
  } else if (!expand) {
    tooltip =
      "Save your search for later. We'll email you automatically if any new campaigns are added matching your search.";
  }

  let onClick = () => {};
  if (!isDisabled) {
    onClick = expand ? saveSearch : () => setExpand(!expand);
  }

  return (
    <div className={`field ${expand ? "has-addons" : ""}`}>
      <div className="control">
        <NameInput
          expand={expand}
          onChangeName={onChangeName}
          nameError={nameError}
        />
      </div>
      <div className="control">
        <a
          className={`button is-darkest
            ${tooltip ? "tooltip tooltip-right-button" : ""}
            ${isDisabled ? "is-faux-disabled" : ""}`}
          data-tooltip={tooltip}
          onClick={onClick}
        >
          <span>Save</span>
          <span className="icon is-small">
            <i className="fa fa-bell" />
          </span>
        </a>
      </div>
    </div>
  );
}
