import React, { useContext, useEffect, useRef } from "react";
import Select from "react-select";
import $ from "jquery";

import CampaignsTable from "./CampaignsTable";
import CityStateInputs from "./CityStateInputs";
import SavedSearches from "./SavedSearches";
import SaveSearchButton from "./SaveSearchButton";
import { store, StateProvider } from "./Store";

import TagSelect from "../shared/TagSelect";

function Location({ stateOptions }) {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { remote } = globalState.state;
  const toggle = useRef();

  return (
    <div className="discover-controls flex-centered">
      <div ref={toggle} className="field has-addons toggle">
        <div className="control">
          <a
            className={`button ${remote ? "is-primary" : "is-darkest"}`}
            onClick={() => dispatch({ type: "SET_REMOTE", remote: true })}
          >
            Remote
          </a>
        </div>
        <div className="control">
          <a
            className={`button ${remote ? "is-darkest" : "is-primary"}`}
            onClick={() => dispatch({ type: "SET_REMOTE", remote: false })}
          >
            In Person
          </a>
        </div>
      </div>
      {remote ? null : <CityStateInputs stateOptions={stateOptions} />}
    </div>
  );
}

function CampaignSearch({
  csrf,
  loggedIn,
  gameOptions,
  stateOptions,
  tagOptions,
}) {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const {
    gameIds,
    tagIds,
    campaigns,
    dirty,
    remote,
    city,
    state,
    showSave,
    selectedSearchId,
  } = globalState.state;

  const search = () => {
    dispatch({ type: "SEARCH", dirty: false, showSave: true });

    fetch(
      `/campaigns/search?${$.param({
        remote,
        city,
        state,
        game_ids: gameIds,
        tag_ids: tagIds,
      })}`
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: "SET_CAMPAIGNS", campaigns: response });
      });
  };

  const setGames = (selected) => {
    dispatch({
      type: "SET_GAME_IDS",
      gameIds: selected ? selected.map((o) => o.value) : [],
    });
  };

  const setTags = (selected) => {
    dispatch({
      type: "SET_TAG_IDS",
      tagIds: selected ? selected.map((o) => o.value) : [],
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    search();
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <form onSubmit={formSubmit}>
      <Location stateOptions={stateOptions} remote={remote} />

      <div className="field is-horizontal discover-selects">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <Select
                inputId="games"
                classNamePrefix="react-select-games"
                options={gameOptions}
                isMulti
                onChange={setGames}
                value={gameIds.map((gid) =>
                  gameOptions.find((option) => option.value === gid)
                )}
                placeholder="Games"
              />
            </div>
          </div>
          <div className="field">
            <TagSelect
              tagOptions={tagOptions}
              tagIds={tagIds}
              onChange={setTags}
            />
          </div>
        </div>
      </div>

      <div className="discover-controls flex-centered">
        <button
          className="button is-primary"
          onClick={search}
          disabled={!dirty}
        >
          Search
        </button>
        {loggedIn && showSave && !dirty && selectedSearchId === "" && (
          <SaveSearchButton csrf={csrf} />
        )}
        {loggedIn && <SavedSearches />}
      </div>

      {!dirty && campaigns ? (
        <CampaignsTable campaigns={campaigns} loggedIn={loggedIn} />
      ) : null}
    </form>
  );
}

function CampaignSearchWithStore({
  csrf,
  loggedIn,
  gameOptions,
  tagOptions,
  stateOptions,
}) {
  return (
    <StateProvider>
      <CampaignSearch
        csrf={csrf}
        loggedIn={loggedIn}
        gameOptions={gameOptions}
        stateOptions={stateOptions}
        tagOptions={tagOptions}
      />
    </StateProvider>
  );
}

export default CampaignSearchWithStore;
